<template>
  <div>

    <!-- Filter Section -->
    <FilterReportCoa title="Filters" :baseReportUrl="baseReportUrl" @clicked-filter="getReport" />

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

        <b-card-body class="text-center">
          <b-card-title>{{ companyName }}</b-card-title>
          <b-card-sub-title>
            <div>{{ $t('apps.reportLedger.singular.ledgerReport') }}</div>
            <div class="mt-sm-1">{{ periodReportText }}</div>
            <div v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</div>
          </b-card-sub-title>
        </b-card-body>

        <b-table-simple id="profit-loss-table">
          <b-thead>
            <b-tr>
              <b-th v-for="column in tableColumns" :key="column.key" :class="column.thClass" >{{ column.label }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(prop) in itemLists" :key="prop.coa" :class="prop.isHeader && 'bolder-border'">
              <b-td class="font-weight-bolder">
                 <div v-if="prop.coa === null ">
                    {{ prop.coa_child }}
                 </div>
                   <div v-else>
                {{ prop.coa }}
               </div>

              </b-td> 

              <b-td :colspan="prop.isHeader ? 5 : 1" :class="[prop.isHeader && 'text-right font-italic font-weight-bolder']">
                {{ prop.isHeader ? 'Balance' : prop.date }}
              </b-td>

              <b-td v-if="!prop.isHeader">
                {{ prop.transaction }}
              </b-td>

              <b-td v-if="!prop.isHeader" :class="[prop.isTotal && 'text-right font-italic font-weight-bolder']">
                {{ prop.remark }}
              </b-td>

              <b-td v-if="!prop.isHeader" :class="['text-right',prop.isTotal && 'font-italic font-weight-bolder' ]">
                {{ prop.debit }}
              </b-td>

              <b-td v-if="!prop.isHeader" :class="['text-right',prop.isTotal && 'font-italic font-weight-bolder' ]">
                {{ prop.credit }}
              </b-td>

              <b-td :class="['text-right',(prop.isHeader || prop.isTotal) && 'font-italic font-weight-bolder']">
                {{ prop.balance }}
              </b-td>

            </b-tr>
          </b-tbody>
          <!-- <b-tfoot>
            <b-tr>
              <b-td class="text-right font-weight-bolder font-italic">Total</b-td>
              <b-td class="text-right font-weight-bolder font-italic">{{ total.balance }}</b-td>
            </b-tr>
          </b-tfoot> -->
        </b-table-simple>

    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  // BTable,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody
  // BLink,
  // BSpinner
} from 'bootstrap-vue'
import FilterReportCoa from '@/components/reports/FilterReportCoa.vue'

import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'
import { unformatNumber } from '@/utils/formatter'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    // BTable,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    // BLink,
    // BSpinner,
    FilterReportCoa
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    // export format
    const formats = ['xlsx', 'csv', 'txt']

    // Table Handlers
    const tableColumns = [
      { key: 'coa', label: 'COA' },
      { key: 'date', label: 'Date' },
      { key: 'transaction', label: 'Transaction' },
      { key: 'remark', label: 'Remark' },
      { key: 'debit', label: 'Debit', thClass: 'text-right', tdClass: 'text-right'  },
      { key: 'credit', label: 'Credit', thClass: 'text-right', tdClass: 'text-right'  },
      { key: 'balance', label: 'Balance', thClass: 'text-right', tdClass: 'text-right'  }
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })
    
    const stylingTrTd = (prop) => {
      return {
        'bolder-border': prop.isHeader
      }
    }

    return {
      stylingTrTd,
      formats,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      ...useFilterReport({
        baseUrl: 'reports/ledger',
        fetchLists
      })
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Ledger Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Ledger Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }
  
  .bolder-border {
    border-top: 2px solid #828282;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
